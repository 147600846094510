<template>
  <div title="">
    <div>
      <span class=" d-block" title="">AgroSavvy,</span>
      <span class=" d-block" title="">10740 109 Str. NW, Edmonton,</span>
      <span class=" d-block" title="">Alberta T5H 3B6, Canada</span>
      <span class=" d-block">info@agrosavvy.com</span>
      <span title="">Tel: +1(780)716-4898</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactDetails",
}
</script>
